import { cEwO, createSVG, dateToReadable, fetchModsList, getHolder } from './helpers';
import getOverride from './overrides';

export function initPage() : void {
    fetchModsList().then((data: Array<GitHubRepository>) => {
        for (let i = 0; i < data.length; i++) {
            let dat: GitHubRepository = data[i];
            const override = getOverride(dat.full_name);

            if (override) {
                dat = {...dat, ...override} as GitHubRepository;
            }

            if (dat.fork) {
                continue;
            }

            const modElement = createMod(dat);
            const holder = getHolder('Mods');
            holder.id = 'mods'

            holder.appendChild(modElement);
        }
    });

    (<HTMLElement>document.querySelector('body > main')).style.display = 'block';
    (<HTMLElement>document.querySelector('body > main > section')).style.maxWidth = '100%';
}

// function createCheckbox(name: string, onClick: Function|undefined = undefined) : HTMLElement {
//     // <input type="checkbox" id="cursed" />       <label for="cursed">Cursed</label>
//     const inputElement = cEwO('input');
//     inputElement.type = 'checkbox';
//
//     inputElement.addEventListener('click', e => {
//         if (onClick) {
//             onClick(e)
//         }
//     })
//
//     return cEwO('label', { elements: [ inputElement, name ]});
// }

// function createCheckboxGroup(groupName: string, boxes: Array<string>) : HTMLElement {
//     const holder = cEwO('div');
//
//     holder.appendChild(cEwO('h4', 'Categories'))
//     boxes.map((box: string) => holder.appendChild(createCheckbox(box)))
//
//     return holder;
// }

function createMod(mod: GitHubRepository) : HTMLElement {
    const imageLink = document.createElement('a');
    imageLink.href = `/mods/?name=${mod.name}` || '#';

    const image = cEwO('img', {classList: 'mod-image'});
    image.src = mod.display_picture || createSVG(mod.name_override || mod.name);
    image.width = image.height = 96;
    image.onerror = () : void => {
        image.onerror = () : void => {};
        image.src = createSVG(mod.name_override || mod.name);
    }

    imageLink.appendChild(image);

    const modNameDiv = cEwO('h3', { elements: mod.name, classList: 'inline'});
    const authorName = cEwO('a', { elements: mod.owner.login || 'boomboompower' });

    authorName.target = '_blank';
    authorName.href = 'https://boomy.wtf'

    const header = cEwO('div', { elements: [ modNameDiv, ' by ', authorName] });
    const description = cEwO('p', { elements: mod.description || 'hello world'});

    // construct div with class='tags' and the topics as children.
    const tags = cEwO('div', { classList: 'tags', elements: (mod.topics.map((o: string) => cEwO('span', { elements: o})))});

    // Construct the second column
    const holder2 = cEwO('div', { elements: [ header, description, tags ]});

    // const forks = document.createElement('div')
    const stars = document.createElement('div')
    const updated = document.createElement('div')
    const updatedTime = dateToReadable(mod.pushed_at);

    // forks.innerText = mod.forks_count + ' forks';
    stars.innerText =
        (mod.stargazers_count > 0 ? `${mod.stargazers_count  } star${mod.stargazers_count === 1 ? '' : 's'}` : '') +
        (mod.forks_count > 0 ? `${(mod.stargazers_count > 0 ? ', ' : '') + mod.forks_count  } fork${mod.forks_count === 1 ? '' : 's'}` : '');

    updated.innerText = updatedTime === undefined ? 'Not updated recently' : `Updated ${updatedTime} ago.`;

    // The third column
    const holder3 = cEwO('div', { elements:[
            stars,
            // forks,
            updated
        ]
    })

    // construct element <article> with class='card' and the three elements above.
    return cEwO('article', { classList: 'card', elements: [ imageLink, holder2, holder3 ]});
}
