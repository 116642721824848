import { cEwO, dateToReadable, getHolder, holder, removeHolder } from './helpers';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

export function initPage(release: GitHubRelease) : void {
    let sourceText = release.body;

    removeHolder('mod-versions')

    const releaseDate = getHolder('release-date');
    if (releaseDate.parentElement) {
        releaseDate.parentElement.prepend(releaseDate)

        const readable = dateToReadable(release.created_at);

        releaseDate.innerText = readable ? `Released  ${dateToReadable(release.created_at)} ago.` : 'Released a long time ago.';
    }

    const holder = getHolder('mod-description');
    holder.id = 'mod-description';

    sourceText = marked.parse(sourceText);
    sourceText = DOMPurify.sanitize(sourceText, {ADD_TAGS: ['iframe']});

    const span = cEwO('span')
    span.innerHTML = sourceText;

    holder.innerHTML = '';
    holder.appendChild(span);

    drawFiles(release.assets)

    // window.scrollTo({top: 0, behavior: 'smooth'})
}

function drawFiles(files: Array<GitHubAsset>) : void {
    const fileHolder = getHolder('mod-files');
    fileHolder.innerHTML = '';
    files.sort((a: GitHubAsset, b: GitHubAsset) => {
        if (a.download_count === b.download_count) {
            const isAMain = a.name.indexOf('-sources') > -1 || a.name.indexOf('-dev') > -1;
            const isBMain = b.name.indexOf('-sources') > -1 || b.name.indexOf('-dev') > -1;

            return (isAMain && isBMain) || !(isAMain || isBMain) ? a.name.localeCompare(b.name) : isAMain ? 1 : -1;
        }

        return a.download_count > b.download_count ? -1 : 1;
    })

    fileHolder.appendChild(cEwO('h3', 'Files'))

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const releaseHolder = cEwO('div', { classList: 'file' });
        const fileName = cEwO('div', {elements: file.name, classList: 'filename'});

        if (i === 0) {
            releaseHolder.appendChild(holder(fileName, cEwO('div', 'PRIMARY')))
        } else {
            releaseHolder.appendChild(holder(fileName))
        }

        const downloadLink = cEwO('a', 'Download');
        downloadLink.href = file.browser_download_url;

        releaseHolder.appendChild(downloadLink)

        fileHolder.appendChild(releaseHolder)
    }
}
