import {
    holder,
    cEwO,
    createSVG,
    getById,
    getHolder,
    dateToReadable,
    fetchModsList,
    fetchModMeta, hashCode, removeHolder, getTotalDownloads
} from './helpers';
import getOverride from './overrides';
import * as releasePage from './release'

import {marked} from 'marked';
import DOMPurify from 'dompurify';

DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (!data.tagName || data.tagName !== 'iframe') {
        return;
    }

    const src = node.getAttribute('src') || '';

    if (src.indexOf('https://www.youtube.com/embed/') !== 0) {
        return node.parentNode?.removeChild(node);
    } else {
        if (node.hasAttribute('width')) node.removeAttribute('width');
        if (node.hasAttribute('height')) node.removeAttribute('height');
    }
});

function findMod(modName: string, searchParams: URLSearchParams) : void {
    fetchModsList().then((data: Array<GitHubRepository>) => {
        for (let i = 0; i < data.length; i++) {
            let dat: GitHubRepository = data[i];
            const override = getOverride(dat.full_name);

            if (override) {
                dat = {...dat, ...override} as GitHubRepository;
            }

            if (dat.fork) {
                continue;
            }

            if (dat.name.toLowerCase() === modName.toLowerCase() || dat.full_name.toLowerCase() === modName.toLowerCase()) {
                const quickLoad = cEwO('img');
                quickLoad.src = dat.header_picture ? dat.header_picture : `https://opengraph.githubassets.com/${hashCode(dat.name)}/${dat.full_name}`;
                quickLoad.width = 0;
                quickLoad.loading = 'eager';
                document.body.appendChild(quickLoad)

                fetchModMeta(dat).then((data: WorkerRepoMeta) => {
                    drawSidebar(dat, data.releases)

                    if (searchParams.has('version') && data.releases.length > 0) {
                        const version = (searchParams.get('version') || '').toLowerCase();

                        if (version === 'latest') {
                            releasePage.initPage(data.releases[0])

                            return;
                        } else if (version === 'all') {
                            drawReleaseListPage(dat, data.releases)

                            return;
                        }

                        for (let i = 0; i < data.releases.length; i++) {
                            const release = data.releases[i];

                            if (release.tag_name.toLowerCase() === version) {
                                releasePage.initPage(release);

                                return;
                            }
                        }
                    }

                    drawMainPage(dat, data.readme);
                })

                // const releases: Promise<Array<GitHubRelease>> = fetchPage(`https://api.github.com/repos/${dat.full_name}/releases?per_page=100`)
                //
                // Promise.all([readme, releases]).then(data => {
                //     drawMod(dat, data[0], data[1]);
                // })

                break;
            }
        }
    })
}

function drawMainPage(mod: GitHubRepository, readme: string)  : void {
    let readmeText;

    if (readme.indexOf('documentation_url') >= 0) {
        readmeText = 'No Readme found';
    } else {
        readmeText = readme
    }

    const holder = getHolder('mod-description');
    holder.id = 'mod-description';

    readmeText = marked.parse(readmeText);
    readmeText = DOMPurify.sanitize(readmeText, {ADD_TAGS: ['iframe']});

    const span = cEwO('span')
    span.innerHTML = readmeText;

    holder.appendChild(span);
}

function drawSidebar(mod: GitHubRepository, assets: Array<GitHubRelease>) : void {
    const holderElement = getById('sidebar');

    const header = document.createElement('img');
    header.classList.add('mod-header');
    header.src = mod.header_picture ? mod.header_picture : `https://opengraph.githubassets.com/${hashCode(mod.name)}/${mod.full_name}`;

    const image = document.createElement('img');
    image.classList.add('mod-image');
    image.src = mod.display_picture || createSVG(mod.name_override || mod.name);
    image.width = image.height = 96;
    image.onerror = () : void => {
        image.onerror = () : void => {};
        image.src = createSVG(mod.name_override || mod.name);
    }

    const modName = document.createElement('h2');
    modName.innerText = mod.name_override || mod.name;
    modName.style.padding = '5px 0';

    const modDescription = document.createElement('div');
    modDescription.classList.add('mod-description-side');
    modDescription.innerText = mod.description;

    const firstSeparator = document.createElement('hr')

    if (holderElement.parentElement) {
        holderElement.parentElement.insertBefore(header, holderElement)
    } else {
        holderElement.innerHTML = '';
    }

    holderElement.appendChild(holder(image))
    holderElement.appendChild(modName)
    holderElement.appendChild(modDescription)
    holderElement.appendChild(firstSeparator)

    holderElement.appendChild(cEwO('div', `Created ${dateToReadable(mod.created_at)} ago.`))
    holderElement.appendChild(cEwO('div', `Updated ${dateToReadable(mod.pushed_at)} ago.`))

    drawResourcesElement(mod, assets)
}

function drawResourcesElement(mod: GitHubRepository, assets: Array<GitHubRelease>) : void {
    const resourcesElement = getById('resources');
    const container = document.createElement('ul');

    if (mod.homepage && mod.homepage.trim().length > 0) {
        const homeItem = document.createElement('li');
        const home = document.createElement('a');
        home.innerText = 'Homepage';
        home.href = mod.homepage;
        home.target = '_blank';

        homeItem.appendChild(home);
        container.appendChild(homeItem)
    }

    if (mod.has_issues) {
        const issuesItem = document.createElement('li');
        const issues = document.createElement('a');
        issues.innerText = 'Issues';
        issues.href = `${mod.html_url}/issues`;
        issues.target = '_blank';

        issuesItem.appendChild(issues)
        container.appendChild(issuesItem)
    }

    const sourceItem = document.createElement('li');
    const source = document.createElement('a');
    source.innerText = 'Source';
    source.href = mod.html_url;
    source.target = '_blank';

    sourceItem.appendChild(source)

    resourcesElement.appendChild(cEwO('h2', { elements: 'External Resources'}));
    container.appendChild(sourceItem);
    resourcesElement.appendChild(container);
    resourcesElement.appendChild(document.createElement('hr'));

    const releasesElement = cEwO('div', {id: 'releases-small'});

    if (assets.length > 0) {
        const latestVersions = cEwO('h4', 'Latest Versions')
        const viewAllElement = cEwO('a', 'View All');
        viewAllElement.href = '#';
        viewAllElement.addEventListener('click', (e: MouseEvent) => {
            e.preventDefault()

            const params = new URLSearchParams(window.location.search)
            params.set('version', 'all');
            const newURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params.toString()}`;
            window.history.pushState({path: newURL}, '', newURL)

            drawReleaseListPage(mod, assets)
        })
        latestVersions.style.display = 'inline-block';
        viewAllElement.style.float = 'right';
        releasesElement.appendChild(holder(latestVersions, viewAllElement))

        for (let i = 0; i < Math.min(assets.length, 10); i++) {
            const release = createRelease(assets[i])
            release.classList.add('small-release')
            releasesElement.appendChild(release)
        }
    } else {
        releasesElement.appendChild(cEwO('span', 'No files released.'))
    }

    resourcesElement.appendChild(releasesElement)

    drawTechnicalInfo(resourcesElement, mod);
}

function drawReleaseListPage(mod: GitHubRepository, assets: Array<GitHubRelease>) : void {
    removeHolder('release-date');
    removeHolder('mod-description');
    removeHolder('mod-files');

    const versionsDiv = getHolder('mod-versions');
    versionsDiv.id = 'mod-versions';

    const headerElement = holder(
        cEwO('div'),
        cEwO('h3', 'Version'),
        cEwO('h3', 'Stats')
    )
    headerElement.classList.add('versions-header')
    versionsDiv.appendChild(headerElement)

    for (let i = 0; i < assets.length; i++) {
        const release = createRelease(assets[i], true)
        release.classList.add('full-release')
        versionsDiv.appendChild(release)
    }
}

function drawTechnicalInfo(parent: HTMLElement, mod: GitHubRepository) : void {
    parent.appendChild(document.createElement('hr'));
    parent.appendChild(cEwO('h2', { elements: 'Project Members'}));

    const container = cEwO('a', { classList: ['small-release', 'author'] });
    const image = cEwO('img');
    image.width = image.height = 40;
    image.src = mod.owner.avatar_url;
    image.onerror = () : void => {
        image.onerror = () : void => {};

        image.src = createSVG('?');
    }

    container.href = mod.owner.html_url;
    container.target = '_blank';

    container.appendChild(image);
    container.appendChild(holder(cEwO('span', mod.owner.login)))

    parent.appendChild(container);

    parent.appendChild(document.createElement('hr'));
    parent.appendChild(cEwO('h2', { elements: 'Technical Information'}));

    const informationContainer = cEwO('div');
    informationContainer.style.display = 'grid';
    informationContainer.style.gridTemplateColumns = '35% 60%';

    informationContainer.appendChild(cEwO('span', {elements: 'License'}))
    const licenseElement = cEwO(mod.license && mod.license.url ? 'a' : 'span', {elements: mod.license && mod.license.url ? mod.license.name : 'Unknown'})

    if (mod.license && mod.license.url && 'href' in licenseElement) {
        // https://spdx.org/licenses/AAL.html
        // licenseElement.href = mod.license.url.replace(/\/api\.github/, '/choosealicense')
        licenseElement.href = `https://spdx.org/licenses/${mod.license.spdx_id}.html`
        licenseElement.target = '_blank';
    }
    informationContainer.appendChild(licenseElement)
    informationContainer.appendChild(cEwO('span', {elements: 'Client Side'}))
    informationContainer.appendChild(cEwO('span', {elements: 'No'}))

    parent.appendChild(informationContainer)
}

function createRelease(release: GitHubRelease, full_release = false) : HTMLElement {
    // const downloadHolder = cEwO('img');
    // downloadHolder.src = createSVG('   ');
    // downloadHolder.width = downloadHolder.height = 40;
    const nameToUse = release.name && release.name.trim().length > 0 ? release.name : (release.prerelease ? 'Prerelease ' : release.draft ? 'Draft ' : 'Release ') + release.tag_name;

    const downloadHolder = cEwO('div', {classList: 'fakeimage'});

    const dataHolder = cEwO('div');
    const releaseName = cEwO('div', {classList: 'name-small', elements: nameToUse});

    const releaseType = cEwO('div');
    releaseType.innerText = release.draft ? 'Draft' : release.prerelease ? 'Prerelease' : 'Release';
    releaseType.classList.add(releaseType.innerText.toLowerCase())
    downloadHolder.classList.add(releaseType.innerText.toLowerCase());

    dataHolder.appendChild(releaseName);

    let clickHandler;

    if (full_release) {
        const releaseTag = cEwO('div', release.tag_name);
        const miniHolder = holder(releaseType, releaseTag);
        releaseTag.classList.add('release-tag');
        miniHolder.classList.add('release-meta');
        dataHolder.appendChild(miniHolder);

        const downloadCount = cEwO('div', `${getTotalDownloads(release)} downloads`);
        // noinspection TypeScriptValidateTypes
        const publishedOn = Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(release.created_at));
        const releaseDate = cEwO('div', `Published on ${publishedOn}`)

        clickHandler = holder(downloadHolder, dataHolder, holder(downloadCount, releaseDate));
    } else {
        dataHolder.appendChild(releaseType);
        clickHandler = holder(downloadHolder, dataHolder);
    }

    clickHandler.classList.add('mod-release')

    clickHandler.addEventListener('click', (ev: MouseEvent) => {
        ev.preventDefault();

        const params = new URLSearchParams(window.location.search)
        params.set('version', release.tag_name);
        const newURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params.toString()}`;
        window.history.pushState({path: newURL}, '', newURL)

        releasePage.initPage(release)
    })

    return clickHandler;
}

export function initPage() : void {
    let foundMod = false;

    if (window.location.search) {
        const searchParams = new URLSearchParams(window.location.search);
        const modName = searchParams.has('name') ? searchParams.get('name') : undefined;

        if (modName && modName.length > 0) {
            foundMod = true;

            findMod(modName, searchParams);
        }
    }

    if (!foundMod) {
        window.location.pathname = '/'
    }
}
